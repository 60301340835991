<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.report.movement.title') }}</h1>
                </div>
            </div>
            <TabsNav strict :items="breadcrumbs()[1].items" />
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-menu
                                        v-model="fromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <TextInput
                                                v-model="from"
                                                :label="$t('accounting.report.movement.from')"
                                                v-bind="attrs"
                                                v-on="on"
                                                id="fromt"
                                            ></TextInput>
                                        </template>
                                        <v-date-picker
                                            v-model="from"
                                            @input="fromMenu = false"

                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                        v-model="toMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <TextInput
                                                v-model="to"
                                                :label="$t('accounting.report.movement.to')"
                                                v-bind="attrs"
                                                v-on="on"
                                                id="fromt"
                                            ></TextInput>
                                        </template>
                                        <v-date-picker
                                            v-model="to"
                                            @input="toMenu = false"

                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <Btn @click="init">{{ $t('accounting.report.get-report') }}</Btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="loading">
                                <v-col>
                                    <v-progress-linear indeterminate :active="loading" class="mx-2"></v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row v-else-if="movements">
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">{{ $t('accounting.report.movement.columns.date') }}</th>
                                                    <th class="text-left">{{ $t('accounting.report.movement.columns.debit') }}</th>
                                                    <th class="text-left">{{ $t('accounting.report.movement.columns.credit') }}</th>
                                                    <th class="text-left">{{ $t('accounting.report.movement.columns.balance') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="movement in movements" :key="movement.id">
                                                    <td>{{ movement.payment_date }}</td>
                                                    <td>{{ formatPrice(movement.income) }}</td>
                                                    <td>{{ formatPrice(movement.outcome) }}</td>
                                                    <td :style="{
                                                        color: movement.final_balance < 0 ? 'red' : 'green'
                                                    }">{{ formatPrice(movement.final_balance) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-alert v-else>
                                {{ $t('accounting.no_data') }}
                            </v-alert>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "MoneyMovementsReport",

    components: {
        TabsNav,
        TextInput,
        Btn,
        Side,
        PageLayout
    },

    mixins: [NavMixin],

    data() {
        return {
            loading: false,

            from: this.$moment().subtract(1, 'month').format('YYYY-MM-DD'),
            to: this.$moment().format('YYYY-MM-DD'),
            fromMenu: false,
            toMenu: false,

            initialBalance: 0,
            movements: []
        }
    },

    computed: {},

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.loading = true;
            this.fetchMovement().then((data) => {
                this.initialBalance = data.initial_balance;
                this.movements = data.data;
            }).finally(() => {
                this.loading = false;
            });
        },

        fetchMovement() {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/accounting/report/movement', {
                    params: {
                        from: this.from,
                        to: this.to
                    }
                }).then(({body}) => {
                    resolve(body);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        formatPrice: (price) => {
            // Проверяем, есть ли дробная часть
            if (Number.isInteger(price)) {
                return price
                    .toString() // Преобразуем в строку
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Разделяем тысячи пробелами
            } else {
                return parseFloat(price)
                    .toFixed(2) // Оставляем два знака после точки
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Разделяем тысячи пробелами
            }
        }
    }
}
</script>
